import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Box, Fab, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {PinspinClient} from "../../api/PinspinClient";
import {ContestInfoClientDto} from "../../api/NswagClient";

export function ContestListPage() {

    const navigate = useNavigate();
    const cities = useSelector(selectCities);
    const [contests, setContests] = useState<ContestInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getContests(0, 50)
            .then(x => setContests(x.items!))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    const handleAddClick = () => {
        navigate('/contests/create');
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

            {
                !loading && !error && contests.length > 0 &&
                <List>
                    {
                        contests.map(x =>
                            <ListItem key={x.contestId} disableGutters disablePadding>
                                <ListItemButton component={Link} to={`/contests/${x.contestId}`}>
                                    <ListItemText primary={x.title}
                                                  secondary={(cityNames.get(x.locationId!) ?? '') + ', ' + x.address}
                                    >
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </List>
            }

            {
                !loading && !error && contests.length === 0 &&
                <Box sx={{ p: 2 }}>
                    <Typography>Пока нет ни одного турнира</Typography>
                </Box>
            }

            {
                !loading && !error &&
                <Fab color="primary" sx={{ position: 'absolute', bottom: 16, right: 16 }} onClick={handleAddClick}>
                    <AddIcon/>
                </Fab>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </Box>
    );
}