import React, {useState} from "react";
import {
    CompetitionBracketClientDto,
    CompetitionMatchClientDto,
    CompetitionMatchStatusClientDto,
    CompetitionStageClientDto,
    ContestClientDto,
    IAccountClientDto
} from "../../../api/NswagClient";
import {
    Avatar,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ContestFormatter} from "../ContestFormatter";
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

export function CompetitionMatches(props: {
    account?: IAccountClientDto,
    contest: ContestClientDto
}) {

    const contest = props.contest;

    return (
        <Paper elevation={0} sx={{ mt: 1 }}>

            <Box>

                <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                    <Typography sx={{ fontSize: 12 }}
                                color="text.secondary"
                                noWrap>
                        Матчи
                    </Typography>
                </Box>

                <Box sx={{ mt: 1 }}>
                    {
                        contest.competition?.stages?.map(stage =>
                            <Box key={stage.order} sx={{ mt: 1 }}>
                                <CompetitionStageView contest={contest} stage={stage}/>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Paper>
    );
}

function CompetitionStageView(props: {
    contest: ContestClientDto,
    stage: CompetitionStageClientDto
}) {

    const contest = props.contest;
    const stage = props.stage;

    return (
        <Box>

            <Typography sx={{ ml: 2, mr: 2, color: 'text.secondary' }}
                        variant='body2'>
                Этап {stage.order}
            </Typography>

            {
                stage.brackets?.map(bracket =>
                    <CompetitionBracketView key={bracket.order}
                                            contest={contest}
                                            bracket={bracket}/>
                )
            }

        </Box>
    );
}

function CompetitionBracketView(props: {
    contest: ContestClientDto,
    bracket: CompetitionBracketClientDto
}) {

    const [isTableView, setIsTableView] = useState(true);

    const contest = props.contest;
    const bracket = props.bracket;

    return (
        <Box key={bracket.order}>

            <Box sx={{
                pl: 2,
                pr: 2,
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center'
            }}>
                <Typography sx={{ color: 'text.secondary' }}
                            variant='body2'>
                    Группа {bracket.order}
                </Typography>

                <Box sx={{ flexGrow: 1 }}></Box>

                <IconButton edge="end"
                            onClick={e => {
                                setIsTableView(!isTableView)
                            }}>
                    {
                        isTableView && <ViewHeadlineIcon/>
                    }
                    {
                        !isTableView && <GridOnIcon/>
                    }
                </IconButton>
            </Box>

            {
                isTableView &&
                <Box>
                    <CompetitionBracketTableView contest={contest} bracket={bracket}/>
                </Box>
            }

            {
                !isTableView &&
                <Box>
                    <CompetitionBracketListView contest={contest} bracket={bracket}/>
                </Box>
            }

        </Box>
    );
}

function CompetitionBracketListView(props: {
    contest: ContestClientDto,
    bracket: CompetitionBracketClientDto
}) {

    const contest = props.contest;
    const bracket = props.bracket;
    const participantsMap = new Map(contest.participants!.map(x => [x.participantId!, x]));

    bracket.matches?.sort((x, y) => {
        const getOrder = (status: CompetitionMatchStatusClientDto) => {
            switch (status) {
                case CompetitionMatchStatusClientDto.Finished:
                    return 0;
                case CompetitionMatchStatusClientDto.Started:
                    return 1;
                case CompetitionMatchStatusClientDto.Created:
                    return 3;
                default:
                    return 4;
            }
        };
        return getOrder(x.status!) - getOrder(y.status!);
    })

    return (
        <List>
            {
                bracket.matches?.map(match =>
                    <ListItem key={match.matchId} disableGutters disablePadding>

                        <ListItemButton>

                            <Box sx={{ flexGrow: 1 }}>

                                <Grid container spacing={2} alignItems={'center'}>

                                    <Grid item xs={5}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 2,
                                            alignItems: 'center'
                                        }}>
                                            <Avatar sx={{ width: 24, height: 24 }}
                                                    src={participantsMap.get(match.competitor1Id!)?.avatar}/>
                                            <Typography
                                                noWrap>{ContestFormatter.formatParticipantNameShort(participantsMap.get(match.competitor1Id!))}</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 2,
                                            alignItems: 'stretch',
                                            justifyContent: 'center'
                                        }}>
                                            {
                                                match.status === CompetitionMatchStatusClientDto.Created &&
                                                <Typography variant='body1'
                                                            noWrap
                                                            sx={{ color: 'text.secondary' }}>
                                                    {
                                                        `- : -`
                                                    }
                                                </Typography>
                                            }

                                            {
                                                match.status === CompetitionMatchStatusClientDto.Started &&
                                                <Typography variant='body1'
                                                            noWrap
                                                            sx={{ color: 'secondary.main' }}>
                                                    {
                                                        `${match.competitor1Score} : ${match.competitor2Score}`
                                                    }
                                                </Typography>
                                            }

                                            {
                                                match.status === CompetitionMatchStatusClientDto.Finished &&
                                                <Typography variant='body1'
                                                            noWrap
                                                            sx={{ color: 'text.secondary' }}>
                                                    {
                                                        `${match.competitor1Score} : ${match.competitor2Score}`
                                                    }
                                                </Typography>
                                            }
                                        </Box>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 2,
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Typography
                                                noWrap>{ContestFormatter.formatParticipantNameShort(participantsMap.get(match.competitor2Id!))}</Typography>
                                            <Avatar sx={{ width: 24, height: 24 }}
                                                    src={participantsMap.get(match.competitor2Id!)?.avatar}/>
                                        </Box>
                                    </Grid>

                                </Grid>

                            </Box>

                        </ListItemButton>
                    </ListItem>
                )
            }

        </List>
    );
}

function CompetitionBracketTableView(props: {
    contest: ContestClientDto,
    bracket: CompetitionBracketClientDto
}) {

    const contest = props.contest;
    const bracket = props.bracket;
    const participantsMap = new Map(contest.participants!.map(x => [x.participantId!, x]));

    const matchesMap = new Map(bracket.matches?.map(x => [`${x.competitor1Id}-${x.competitor2Id}`, x]));
    const getMatchScore = (x: number, y: number) => {

        const getColor = (c: CompetitionMatchClientDto, direct: boolean) => {
            if (c.status === CompetitionMatchStatusClientDto.Created) {
                return 'text.secondary'
            }

            if (c.status === CompetitionMatchStatusClientDto.Started) {
                return 'secondary.main';
            }

            if (direct) {
                return (c.competitor1Score! > c.competitor2Score! ? 'success.main' : 'error.main')
            } else {
                return (c.competitor2Score! > c.competitor1Score! ? 'success.main' : 'error.main')
            }
        }

        if (x === y) {
            return { text: '', color: 'text.primary' };
        }

        const xy = matchesMap.get(`${x}-${y}`);
        if (xy) {
            return {
                text: `${xy.competitor1Score}:${xy.competitor2Score}`,
                color: getColor(xy, true)
            };
        }

        const yx = matchesMap.get(`${y}-${x}`);
        if (yx) {
            return {
                text: `${yx.competitor2Score}:${yx.competitor1Score}`,
                color: getColor(yx, false)
            };
        }

        return { text: '', color: 'text.primary' };
    };

    return (
        <Box sx={{ pt: 1, pb: 1 }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Участник</TableCell>

                            {
                                bracket.standings?.map((x, index) =>
                                    <TableCell key={x.competitorId}>{index + 1}</TableCell>
                                )
                            }

                            <TableCell>О</TableCell>
                            <TableCell>М</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bracket.standings?.map((standing, index) =>
                                <TableRow key={standing.competitorId}>

                                    <TableCell>{index + 1}</TableCell>

                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                        {
                                            ContestFormatter.formatParticipantNameShort(participantsMap.get(standing.competitorId!))
                                        }
                                    </TableCell>

                                    {
                                        bracket.standings?.map(x => {
                                            const score = getMatchScore(standing.competitorId!, x.competitorId!);
                                            return (
                                                <TableCell key={x.competitorId} sx={{ color: score.color }}>
                                                    {score.text}
                                                </TableCell>
                                            )
                                        })
                                    }

                                    <TableCell>{standing.points}</TableCell>
                                    <TableCell>{standing.position}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}