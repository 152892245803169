export class EmailValidator {

    static validate(email: string) {
        if (!email) {
            return false;
        }

        if (email.trim() === '') {
            return false;
        }

        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim());
    }
}