import {
    PlayerDominantHandClientDto,
    PlayerGripClientDto,
    PlayerInfoClientDto,
    PlayerPlayingStyleClientDto
} from "../../api/NswagClient";

export class PlayerFormatter {

    static formatName(player: PlayerInfoClientDto) {
        if (player.firstName && player.lastName) {
            return `${player.firstName} ${player.lastName}`;
        }
        return player.username;
    }

    static formatGrip(grip: PlayerGripClientDto) {

        if (grip === PlayerGripClientDto.Shakehand) {
            return 'Европейский';
        }

        if (grip === PlayerGripClientDto.Penhold) {
            return 'Азиатский';
        }

        return '-';
    }

    static formatDominantHand(dominantHand: PlayerDominantHandClientDto) {

        if (dominantHand === PlayerDominantHandClientDto.Right) {
            return 'Правая';
        }

        if (dominantHand === PlayerDominantHandClientDto.Left) {
            return 'Левая';
        }

        return '-';
    }

    static formatPlayingStyle(playingStyle: PlayerPlayingStyleClientDto) {

        if (playingStyle === PlayerPlayingStyleClientDto.Attack) {
            return 'Атака';
        }

        if (playingStyle === PlayerPlayingStyleClientDto.Defence) {
            return 'Защита';
        }

        return '-';
    }
}