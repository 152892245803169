import {ValidationResult} from "./ValidationResult";

export class PasswordValidator {

    static readonly MinLength = 6;
    static readonly MaxLength = 50;

    static validate(password: string): ValidationResult {
        if (!password) {
            return { isValid: false, error: 'Не должно быть пустым' };
        }

        if (password.trim() === '') {
            return { isValid: false, error: 'Не должно быть пустым' };
        }

        if (password.trim().length < this.MinLength) {
            return { isValid: false, error: 'Длина должна быть не мнее 6 символов' };
        }

        if (password.trim().length > this.MaxLength) {
            return { isValid: false, error: 'Длина не должна превышать 50 символов' };
        }

        return { isValid: true, error: '' };
    }
}