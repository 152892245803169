import {
    Alert,
    Avatar,
    Box,
    Checkbox,
    Container,
    CssBaseline, FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {AccountService} from "./AccountService";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {EmailValidator} from "./EmailValidator";
import {useFormik} from "formik";
import {Profile, setAccount, setProfile} from "./accountSlice";
import {useAppDispatch} from "../../app/hooks";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {AccountRoleClientDto} from "../../api/NswagClient";
import {PinspinClient} from "../../api/PinspinClient";

export function SignInPage() {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [accountActivated, setAccountActivated] = useState(true);
    const [activationEmailSubmitted, setActivationEmailSubmitted] = useState(false);

    const formik = useFormik<LoginForm>({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validate: values => {
            const errors = {};
            if (values.email.trim().length === 0) {
                Object.assign(errors, { email: 'Введите почтовый адрес' });
            }
            if (!EmailValidator.validate(values.email)) {
                Object.assign(errors, { email: 'Введите корректный email адрес' });
            }
            if (values.password.trim().length === 0) {
                Object.assign(errors, { password: 'Введите пароль' });
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(undefined);

                const accountService = new AccountService();
                await accountService.signIn(values.email.trim(), values.password.trim(), values.remember);

                const account = await accountService.getAccount();
                let profile: Profile | undefined;

                if (account.role === AccountRoleClientDto.Player) {
                    profile = await new PinspinClient().getPlayerProfile(account.userId!);
                } else if (account.role === AccountRoleClientDto.Coach) {
                    profile = await new PinspinClient().getCoachProfile(account.userId!);
                } else if (account.role === AccountRoleClientDto.Gym) {
                    profile = await new PinspinClient().getGymProfile(account.userId!);
                } else if (account.role === AccountRoleClientDto.Store) {
                    profile = await new PinspinClient().getStoreProfile(account.userId!);
                }

                dispatch(setAccount({ ...account }));
                dispatch(setProfile({ ...profile }));

                let from = location.state?.from?.pathname || '/';
                navigate(from);
            } catch (e) {
                const text = ErrorFormatter.format(e);
                if (text.includes('Аккаунт не активирован')) {
                    setAccountActivated(false);
                }
                setError(e);
            }
        }
    });

    const handleSendActivationToken = async () => {
        try {
            setLoading(true);
            setError(undefined);

            const accountService = new AccountService();
            await accountService.sendActivationEmail(formik.values.email);

            setLoading(false);
            setActivationEmailSubmitted(true);
        } catch (e) {
            setLoading(false);
            setError(e);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Вход
                </Typography>

                <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>

                    {
                        error &&
                        <Alert sx={{ mb: 2 }} severity='error'>{ErrorFormatter.format(error)}</Alert>
                    }

                    {
                        activationEmailSubmitted &&
                        <Alert sx={{ mb: 2 }} severity='success'>
                            {`На почту '${formik.values.email}' отправлено письмо с инструкцией по активации аккаунта`}
                        </Alert>
                    }

                    {
                        accountActivated &&
                        <>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Почтовый адрес"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                autoComplete="current-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            <FormControlLabel
                                control={<Checkbox name='remember' color="primary"
                                                   value={formik.values.remember}
                                                   onChange={formik.handleChange}/>}
                                label="Запомнить"
                            />

                            <LoadingButton
                                loading={formik.isSubmitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Войти
                            </LoadingButton>

                            <Grid container>
                                <Grid item xs>
                                    <Link variant="body2" component={RouterLink} to={'/account/recovery'}>
                                        Забыли пароль?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link variant="body2" component={RouterLink} to={'/account/signup'}>
                                        Регистрация
                                    </Link>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {
                        !accountActivated && !activationEmailSubmitted &&
                        <>

                            <Typography variant='body1'>
                                Пройдите по ссылке из письма, полученному при регистрации
                            </Typography>

                            <LoadingButton
                                onClick={handleSendActivationToken}
                                loading={loading}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Отправить повторно
                            </LoadingButton>
                        </>
                    }

                </Box>
            </Box>
        </Container>
    );
}

interface LoginForm {
    email: string;
    password: string;
    remember: boolean;
}