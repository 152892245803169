import {LogicError} from "./LogicError";
import {ApiExceptionResponse} from "../../api/NswagClient";

export class ErrorFormatter {

    static format(error: any): string {

        if (error instanceof LogicError) {
            return error.message;
        }

        if (error instanceof ApiExceptionResponse && error.code === 400) {
            return error.message ?? '';
        }

        if (error instanceof ApiExceptionResponse && error.code === 404) {
            return error.message ?? '';
        }

        if (error instanceof ApiExceptionResponse && error.code && error.code >= 500) {
            return 'Что то пошло не так, повторите попытку позже';
        }

        return 'Что то пошло не так, повторите попытку позже';
    }
}