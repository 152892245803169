import React from "react";
import {Box} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import {selectAccount, selectProfile} from "../account/accountSlice";
import {CoachProfileEdit} from "./CoachProfileEdit";
import {GymProfileEdit} from "./GymProfileEdit";
import {StoreProfileEdit} from "./StoreProfileEdit";
import {PlayerProfileEdit} from "./PlayerProfileEdit";
import {
    AccountRoleClientDto,
    CoachProfileClientDto,
    GymProfileClientDto,
    PlayerProfileClientDto, StoreProfileClientDto
} from "../../api/NswagClient";

export function ProfileEditPage() {

    const account = useAppSelector(selectAccount);
    const profile = useAppSelector(selectProfile);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>

            {
                account && account.role === AccountRoleClientDto.Player && profile &&
                <PlayerProfileEdit profile={profile as PlayerProfileClientDto}/>
            }

            {
                account && account.role === AccountRoleClientDto.Coach && profile &&
                <CoachProfileEdit profile={profile as CoachProfileClientDto}/>
            }

            {
                account && account.role === AccountRoleClientDto.Gym && profile &&
                <GymProfileEdit profile={profile as GymProfileClientDto}/>
            }

            {
                account && account.role === AccountRoleClientDto.Store && profile &&
                <StoreProfileEdit profile={profile as StoreProfileClientDto}/>
            }

        </Box>
    );
}