import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingComponent} from "../../common/LoadingComponent";
import {ErrorComponent} from "../../common/ErrorComponent";
import {Alert, Box, Button, MobileStepper} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight, PlayArrow} from "@mui/icons-material";
import {SummaryStep} from "./SummaryStep";
import {FormatSettings, FormatSettingsStep} from "./FormatSettingsStep";
import {FormatTypeStep} from "./FormatTypeStep";
import {TablesSelectStep} from "./TablesSelectStep";
import {ParticipantsSelectStep} from "./ParticipantsSelectStep";
import {LoadingButton} from "@mui/lab";
import {ErrorFormatter} from "../../common/ErrorFormatter";
import {useSnackbar} from "notistack";
import {LogicError} from "../../common/LogicError";
import {PinspinClient} from "../../../api/PinspinClient";
import {
    ContestClientDto,
    ContestFormatClientDto,
    ContestFormatTypeClientDto,
    ContestStartClientDto
} from "../../../api/NswagClient";

export function ContestStartPage() {

    const params = useParams<{ id: string }>();
    const [contest, setContest] = useState<ContestClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getContest(Number.parseInt(params.id ?? '') ?? '')
            .then(x => setContest(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.id]);

    return (
        <>
            {
                contest &&
                <ContestStartForm contest={contest}/>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}

function ContestStartForm(props: { contest: ContestClientDto }) {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [participantIds, setParticipantIds] = useState<number[]>([]);
    const [tablesCount, setTablesCount] = useState(1);
    const [formatType, setFormatType] = useState(ContestFormatTypeClientDto.RoundRobin);
    const [formatSettings, setFormatSettings] = useState<FormatSettings>({
        groupsCount: 1,
        winsCount: 3,
        roundsCount: 1
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const handleNext = () => {
        if (activeStep === 0 && participantIds.length < 2) {
            enqueueSnackbar(ErrorFormatter.format(LogicError.new('Выберите минимум двух игроков')), { variant: "error" });
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleParticipantsChanged = (participants: number[]) => {
        setParticipantIds(participants);
        setError(undefined);
    };

    const handleStart = async () => {
        try {
            setLoading(true);
            await new PinspinClient().startContest(props.contest.contestId!, new ContestStartClientDto({
                participantIds: participantIds,
                format: new ContestFormatClientDto({
                    type: formatType,
                    tablesCount: tablesCount,
                    groupsCount: formatSettings.groupsCount,
                    winsCount: formatSettings.winsCount,
                    roundsCount: formatSettings.roundsCount
                })
            }));
            enqueueSnackbar('Турнир создан', { variant: "success" });
            navigate(`/contests/${props.contest.contestId}`);
        } catch (e: any) {
            setLoading(false);
            setError(e);
        }
    };

    const maxSteps = 5;

    return (
        <>
            <Box sx={{ bgcolor: 'background.paper' }}>

                <Box>
                    {
                        activeStep === 0 &&
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <ParticipantsSelectStep contest={props.contest}
                                                    checked={participantIds}
                                                    onChange={handleParticipantsChanged}/>
                        </Box>
                    }

                    {
                        activeStep === 1 &&
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <TablesSelectStep contest={props.contest}
                                              tablesCount={tablesCount}
                                              onChange={x => setTablesCount(x)}/>
                        </Box>
                    }

                    {
                        activeStep === 2 &&
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <FormatTypeStep contest={props.contest}
                                            format={formatType}
                                            onChange={x => setFormatType(x)}/>
                        </Box>
                    }

                    {
                        activeStep === 3 &&
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <FormatSettingsStep contest={props.contest}
                                                settings={formatSettings}
                                                onChange={x => setFormatSettings(x)}/>
                        </Box>
                    }

                    {
                        activeStep === 4 &&
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <SummaryStep contest={props.contest}
                                         participants={participantIds}
                                         tablesCount={tablesCount}
                                         format={formatType}
                                         settings={formatSettings}/>
                        </Box>
                    }
                </Box>

                {
                    error &&
                    <Alert sx={{ mb: 2 }} severity='error'>
                        {ErrorFormatter.format(error)}
                    </Alert>
                }

                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <LoadingButton
                            size="small"
                            loading={loading}
                            onClick={activeStep < maxSteps - 1 ? handleNext : handleStart}
                        >
                            {
                                activeStep < maxSteps - 1 &&
                                <>
                                    Далее
                                    <KeyboardArrowRight/>
                                </>
                            }
                            {
                                activeStep === maxSteps - 1 &&
                                <>
                                    Начать
                                    <PlayArrow/>
                                </>
                            }
                        </LoadingButton>
                    }
                    backButton={
                        <Button size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0 || loading}>
                            <KeyboardArrowLeft/>
                            Назад
                        </Button>
                    }
                />
            </Box>
        </>
    );
}
