import {AccountRoleClientDto} from "../../api/NswagClient";

export class RoleFormatter {

    static format(role: AccountRoleClientDto): string {
        switch (role) {
            case AccountRoleClientDto.Admin: return 'Администратор';
            case AccountRoleClientDto.Manager: return 'Менеджер';
            case AccountRoleClientDto.Coach: return 'Тренер';
            case AccountRoleClientDto.Player: return 'Игрок';
            case AccountRoleClientDto.Gym: return 'Площадка';
            case AccountRoleClientDto.Store: return 'Магазин';
            default: return '';
        }
    }
}