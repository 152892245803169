import {ValidationResult} from "./ValidationResult";

export class UsernameValidator {

    static readonly MinLength = 3;
    static readonly MaxLength = 50;

    static validate(username: string): ValidationResult {
        if (!username) {
            return { isValid: false, error: 'Не должно быть пустым' };
        }

        if (username.trim() === '') {
            return { isValid: false, error: 'Не должно быть пустым' };
        }

        if (!/^[0-9a-zA-Z_-]+$/.test(username.trim())) {
            return { isValid: false, error: 'Разрешены только латинские смиволы, числа или знаки _ или -' };
        }

        if (!/^[a-zA-Z]+$/.test(username.trim()[0])) {
            return { isValid: false, error: 'Должно начинаться с латинского смивола' };
        }

        if (username.trim().length < this.MinLength) {
            return { isValid: false, error: 'Длина должна быть не мнее 3 символов' };
        }

        if (username.trim().length > this.MaxLength) {
            return { isValid: false, error: 'Длина не должна превышать 50 символов' };
        }

        return { isValid: true, error: '' };
    }
}

