import {useParams} from "react-router-dom";
import {useEffect} from "react";

export function StorePage() {

    const params = useParams<{ username: string }>();

    useEffect(() => {

    }, [params.username]);

    return (
        <></>
    );
}