import {
    ApplyGameResultClientDto,
    CompetitionGameClientDto,
    CompetitionGameStatusClientDto,
    CompetitionMatchClientDto,
    CompetitionTableClientDto,
    ContestClientDto,
    ContestParticipantClientDto
} from "../../../api/NswagClient";
import React, {useState} from "react";
import {Alert, Box, Dialog, DialogContent, IconButton, Menu, MenuItem, TextField, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useSnackbar} from "notistack";
import {useFormik} from "formik";
import {PinspinClient} from "../../../api/PinspinClient";
import {ErrorFormatter} from "../../common/ErrorFormatter";
import {LoadingButton} from "@mui/lab";

export function CompetitionApplyGameResultDialog(props: {
    contest: ContestClientDto,
    table: CompetitionTableClientDto,
    open: boolean,
    onClose: () => void,
    onChange: (contest: ContestClientDto) => void
}) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [contextMenuGame, setContextMenuGame] = useState<CompetitionGameClientDto>();
    const [isChanging, setIsChanging] = useState(false);
    const participantsMap = new Map(props.contest.participants!.map(x => [x.participantId!, x]));

    const handleOpenMatchContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMatchContextMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogContent>

                {
                    <Typography variant='body1'>Стол {props.table.number}</Typography>
                }

                {
                    props.table.match?.games?.map(game =>
                        <Box key={game.gameId} sx={{ pt: 1 }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                <Typography variant='body2'>Сет {game.order}</Typography>

                                <Box sx={{ flexGrow: 1 }}></Box>

                                <IconButton edge="end"
                                            disabled={game.status !== CompetitionGameStatusClientDto.Finished}
                                            onClick={e => {
                                                handleOpenMatchContextMenu(e)
                                                setContextMenuGame(game);
                                            }}>
                                    <MoreVertIcon/>
                                </IconButton>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMatchContextMenu}
                                >
                                    <MenuItem onClick={() => {
                                        handleCloseMatchContextMenu();
                                        setIsChanging(true);
                                    }}>
                                        Изменить
                                    </MenuItem>
                                </Menu>
                            </Box>

                            <ApplyGameResultForm contest={props.contest}
                                                 match={props.table.match!}
                                                 game={game}
                                                 enabled={(isChanging && contextMenuGame !== undefined && contextMenuGame.gameId === game.gameId) || (!isChanging && game.status === CompetitionGameStatusClientDto.Started)}
                                                 participants={participantsMap}
                                                 onChange={x => {
                                                     setIsChanging(false);
                                                     setContextMenuGame(undefined);
                                                     props.onChange(x);
                                                 }}/>

                        </Box>
                    )
                }

            </DialogContent>

        </Dialog>
    );
}

function ApplyGameResultForm(props: {
    contest: ContestClientDto,
    match: CompetitionMatchClientDto,
    game: CompetitionGameClientDto,
    enabled: boolean,
    participants: Map<number, ContestParticipantClientDto>,
    onChange: (contest: ContestClientDto) => void
}) {

    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<any>();

    const formik = useFormik<ApplyGameResultFormData>({
        initialValues: {
            competitor1Score: props.game?.competitor1Points ?? 0,
            competitor2Score: props.game?.competitor2Points ?? 0,
        },
        validate: values => {
            const errors = {};
            if (values.competitor1Score < 0) {
                Object.assign(errors, { competitor1Score: 'Значение не может быть отрицательным' });
            }
            if (values.competitor2Score < 0) {
                Object.assign(errors, { competitor2Score: 'Значение не может быть отрицательным' });
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(undefined);
                const client = new PinspinClient();

                if (props.game.status === CompetitionGameStatusClientDto.Started) {
                    await client.applyGameResult(
                        props.contest.contestId!,
                        props.game.gameId!,
                        new ApplyGameResultClientDto({
                            competitor1Score: values.competitor1Score,
                            competitor2Score: values.competitor2Score
                        }));
                }
                else if (props.game.status === CompetitionGameStatusClientDto.Finished) {
                    await client.changeGameResult(
                        props.contest.contestId!,
                        props.game.gameId!,
                        new ApplyGameResultClientDto({
                            competitor1Score: values.competitor1Score,
                            competitor2Score: values.competitor2Score
                        }));
                }

                const contest = await client.getContest(props.contest.contestId!);
                props.onChange(contest);

                enqueueSnackbar('Счет сета применен ', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Box component='form' onSubmit={formik.handleSubmit}>

            {
                error &&
                <Alert sx={{ mt: 1, mb: 1 }} severity='error'>{ErrorFormatter.format(error)}</Alert>
            }

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <TextField
                    margin="normal"
                    required
                    label={props.match.competitor1Id ? props.participants.get(props.match.competitor1Id)?.username : ''}
                    name="competitor1Score"
                    type='number'
                    disabled={!props.enabled}
                    value={formik.values.competitor1Score}
                    onChange={formik.handleChange}
                    error={formik.touched.competitor1Score && Boolean(formik.errors.competitor1Score)}
                    helperText={formik.touched.competitor1Score && formik.errors.competitor1Score}
                />

                <TextField
                    margin="normal"
                    required
                    label={props.match.competitor2Id ? props.participants.get(props.match.competitor2Id)?.username : ''}
                    name="competitor2Score"
                    type='number'
                    disabled={!props.enabled}
                    value={formik.values.competitor2Score}
                    onChange={formik.handleChange}
                    error={formik.touched.competitor2Score && Boolean(formik.errors.competitor2Score)}
                    helperText={formik.touched.competitor2Score && formik.errors.competitor2Score}
                />
            </Box>


            {
                props.enabled &&
                <LoadingButton
                    loading={formik.isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                >
                    Применить
                </LoadingButton>
            }
        </Box>
    );
}

interface ApplyGameResultFormData {
    competitor1Score: number;
    competitor2Score: number;
}