import React, {useRef, useState} from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    useTheme
} from "@mui/material";

import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import {RoleFormatter} from "../common/RoleFormatter";
import {useAppDispatch} from "../../app/hooks";
import {logout, Profile} from "../account/accountSlice";
import {useNavigate} from "react-router-dom";
import {ProfileFormatter} from "../common/ProfileFormatter";
import {AccountRoleClientDto, IAccountClientDto} from "../../api/NswagClient";

const profiles = new Set([AccountRoleClientDto.Player, AccountRoleClientDto.Coach, AccountRoleClientDto.Gym, AccountRoleClientDto.Store]);

export function ProfileComponent(props: { account: IAccountClientDto, profile: Profile }) {

    const account = props.account;
    const profile = props.profile;
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const anchor = useRef(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        if (!open) setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProfileItemClick = () => {

        setOpen(false);

        if (account.role === AccountRoleClientDto.Player) {
            navigate(`/players/${account.username}`);
            return;
        }

        if (account.role === AccountRoleClientDto.Coach) {
            navigate(`/coaches/${account.username}`);
            return;
        }

        if (account.role === AccountRoleClientDto.Gym) {
            navigate(`/gyms/${account.username}`);
            return;
        }

        if (account.role === AccountRoleClientDto.Store) {
            navigate(`/stores/${account.username}`);
            return;
        }
    };

    const handleEdit = () => {
        setOpen(false);
        navigate('profile/edit');
    };

    const handleSettings = () => {
        setOpen(false);
        navigate('profile/settings');
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/account/signin');
    };

    return (
        <Box>
            <IconButton sx={{ p: 0, ml: 1 }} ref={anchor} onClick={handleToggle}>
                <Avatar alt="Profile" src={profile.avatar} sx={{ width: 36, height: 36 }}/>
            </IconButton>

            <Popper open={open} anchorEl={anchor.current} placement={'bottom-end'} disablePortal>
                <Paper sx={{
                    width: '100%',
                    minWidth: 285,
                    maxWidth: 285,
                }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Card>
                            <CardContent>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Stack direction="row" spacing={1.25} alignItems="center">
                                            <Avatar alt="Avatar" src={profile.avatar} sx={{ width: 48, height: 48 }}/>
                                            <Stack>
                                                <Typography variant="body1">
                                                    {ProfileFormatter.format(account, profile)}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {RoleFormatter.format(account.role!)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <Divider/>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <List component="nav"
                                      sx={{
                                          p: 0,
                                          '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] }
                                      }}>

                                    {
                                        profiles.has(account.role!) &&
                                        <ListItemButton onClick={() => handleProfileItemClick()}>
                                            <ListItemIcon>
                                                <PersonIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Профиль"/>
                                        </ListItemButton>
                                    }

                                    <ListItemButton onClick={handleEdit}>
                                        <ListItemIcon>
                                            <EditIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Редактировать"/>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleSettings}>
                                        <ListItemIcon>
                                            <SettingsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Настройки"/>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleLogout}>
                                        <ListItemIcon>
                                            <LogoutIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Выйти"/>
                                    </ListItemButton>

                                </List>
                            </Box>
                        </Card>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </Box>
    );
}