import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Avatar, Box, Paper, Typography} from "@mui/material";
import {CoachFormatter} from "./CoachFormatter";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {PinspinClient} from "../../api/PinspinClient";
import {CoachClientDto} from "../../api/NswagClient";
import {TitleComponent} from "../common/TitleComponent";

export function CoachPage() {

    const params = useParams<{ username: string }>();
    const cities = useSelector(selectCities);
    const [coach, setCoach] = useState<CoachClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getCoach(params.username ?? '')
            .then(x => setCoach(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.username]);

    const citiesMap = new Map(cities.map(x => [x.cityId, x.name]));

    return (
        <>
            {
                !loading && !error && coach &&
                <>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Avatar src={coach.avatar} sx={{ width: 56, height: 56 }} variant='circular'/>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TitleComponent>
                                    {CoachFormatter.formatName(coach)}
                                </TitleComponent>
                                <Typography>
                                    {citiesMap.get(coach.cityId!) ?? ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>
    );
}