import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {PinspinClient} from "../../api/PinspinClient";
import {ICityClientDto} from "../../api/NswagClient";

export interface CoreState {
    cities: ICityClientDto[];
}

const initialState: CoreState = {
    cities: []
};

export const coreSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setCities: (state, action: PayloadAction<ICityClientDto[]>) => {
            state.cities = action.payload;
        }
    }
});


export const getCities = (): AppThunk => async (dispatch, getState) => {
    try {
        const pagination = await new PinspinClient().getCities(0, 1000);
        dispatch(setCities(pagination.items!.map(x => ({ cityId: x.cityId, name: x.name }))));
    } catch (e) {

    }
};

export const { setCities } = coreSlice.actions;

export const selectCities = (state: RootState) => state.core.cities;