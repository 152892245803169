import React, {useState} from "react";
import {
    Avatar,
    Box,
    Checkbox, FormHelperText,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import {ContestFormatter} from "../ContestFormatter";
import {useSelector} from "react-redux";
import {selectCities} from "../../core/coreSlice";
import {ContestClientDto} from "../../../api/NswagClient";

export function ParticipantsSelectStep(props: {
    contest: ContestClientDto,
    checked: number[],
    onChange: (checked: number[]) => void
}) {

    const cities = useSelector(selectCities);
    const [checked, setChecked] = useState<number[]>(props.checked);

    const handleToggle = (participantId: number) => {
        const currentIndex = checked.indexOf(participantId);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(participantId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        props.onChange(newChecked);
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));

    return (
        <>
            <Box sx={{ pt: 2, pl: 2, pr: 2, pb: 1 }}>
                <Typography variant='h5'>Игроки</Typography>
            </Box>

            <List>
                {
                    props.contest.participants!.map((participant) =>
                        <ListItem
                            key={participant.participantId}
                            disableGutters
                            disablePadding
                            secondaryAction={
                                <Checkbox checked={checked.indexOf(participant.participantId!) !== -1}
                                          onChange={() => handleToggle(participant.participantId!)}
                                />
                            }
                        >
                            <ListItemButton onClick={() => handleToggle(participant.participantId!)}>
                                <ListItemAvatar>
                                    <Avatar src={participant.avatar}/>
                                </ListItemAvatar>
                                <ListItemText primary={ContestFormatter.formatParticipantName(participant)}
                                              secondary={`${cityNames.get(participant.locationId) ?? ''}, ${ContestFormatter.formatParticipantRating(participant)}`}/>
                            </ListItemButton>
                        </ListItem>
                    )
                }
            </List>

            <Box sx={{ pt: 1, pl: 2, pr: 2, pb: 2 }}>
                <FormHelperText>
                    Выберите игроков, участвующих в турнире
                </FormHelperText>
            </Box>
        </>
    );
}