import {
    Alert,
    Avatar,
    Box,
    Container,
    CssBaseline, Link,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useFormik} from "formik";
import {AccountService} from "./AccountService";
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {ErrorFormatter} from "../common/ErrorFormatter";

export function PasswordResetPage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [error, setError] = useState<any>();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!navigate || !searchParams) {
            return;
        }

        const email = searchParams.get('email');
        if (!email) {
            navigate('/error');
            return;
        }
        setEmail(email);

        const token = searchParams.get('token');
        if (!token) {
            navigate('/error');
            return;
        }
        setToken(token);
    }, [navigate, searchParams]);

    const formik = useFormik<ResetForm>({
        initialValues: {
            password: '',
            repeatPassword: ''
        },
        validate: values => {
            const errors = {};
            if (values.password.trim().length === 0) {
                Object.assign(errors, {password: 'Введите пароль'});
            }
            if (values.password.trim().length < 6) {
                Object.assign(errors, {password: 'Длина пароля должна быть не менее 6 символов'});
            }
            if (values.password !== values.repeatPassword) {
                Object.assign(errors, {repeatPassword: 'Пароли должны совпадать'});
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(undefined);
                const accountService = new AccountService();
                await accountService.resetPassword(email, token, values.password.trim());
                setSubmitted(true);
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Изменение пароля
                </Typography>

                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>

                    {
                        error &&
                        <Alert sx={{mb: 2}} severity='error'>
                            {ErrorFormatter.format(error)}
                        </Alert>
                    }

                    {
                        submitted &&
                        <>
                            <Alert sx={{mb: 2}} severity='success'>
                                {`Пароль изменен, войдите в систему`}
                            </Alert>

                            <Link component={RouterLink} to='/account/signin'>Вход / регистрация</Link>
                        </>
                    }

                    {
                        !submitted &&
                        <>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repeatPassword"
                                label="Повторите пароль"
                                type="password"
                                value={formik.values.repeatPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                            />

                            <LoadingButton
                                loading={formik.isSubmitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Сохранить
                            </LoadingButton>
                        </>
                    }
                </Box>
            </Box>
        </Container>
    );
}

interface ResetForm {
    password: string;
    repeatPassword: string;
}