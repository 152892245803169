import {Profile} from "../account/accountSlice";
import {
    AccountRoleClientDto,
    CoachProfileClientDto,
    GymProfileClientDto, IAccountClientDto,
    PlayerProfileClientDto, StoreProfileClientDto
} from "../../api/NswagClient";

export class ProfileFormatter {

    static format(account: IAccountClientDto, profile: Profile) {

        if (account.role === AccountRoleClientDto.Player) {
            const p = profile as PlayerProfileClientDto;
            return `${p.firstName} ${p.lastName}`
        }

        if (account.role === AccountRoleClientDto.Coach) {
            const p = profile as CoachProfileClientDto;
            return `${p.firstName} ${p.lastName}`
        }

        if (account.role === AccountRoleClientDto.Gym) {
            const p = profile as GymProfileClientDto;
            return p.name;
        }

        if (account.role === AccountRoleClientDto.Store) {
            const p = profile as StoreProfileClientDto;
            return p.name;
        }

        return "";
    }
}