import {CoachInfoClientDto} from "../../api/NswagClient";

export class CoachFormatter {

    static formatName(coach: CoachInfoClientDto) {
        if (coach.firstName && coach.lastName) {
            return `${coach.firstName} ${coach.lastName}`;
        }
        return coach.username;
    }
}