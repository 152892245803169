import {GymInfoClientDto} from "../../api/NswagClient";

export class GymFormatter {

    static formatName(gym: GymInfoClientDto) {
        if (gym.name) {
            return gym.name;
        }
        return gym.username;
    }
}