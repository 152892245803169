import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {FormatSettings} from "./FormatSettingsStep";
import {ContestClientDto, ContestFormatTypeClientDto} from "../../../api/NswagClient";
import {ContestFormatter} from "../ContestFormatter";
import {TitleComponent} from "../../common/TitleComponent";

export function SummaryStep(props: {
    contest: ContestClientDto,
    participants: number[],
    tablesCount: number,
    format: ContestFormatTypeClientDto,
    settings: FormatSettings
}) {

    return (
        <Box sx={{ p: 2 }}>

            <Box>
                <TitleComponent>Запуск</TitleComponent>
            </Box>

            <Grid container spacing={1} sx={{ mt: 2 }}>

                <Grid item xs={6}>
                    <Typography sx={{ color: 'text.secondary' }}>Участники</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{props.participants.length}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ color: 'text.secondary' }}>Столы</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{props.tablesCount}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ color: 'text.secondary' }}>Формат</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{ContestFormatter.formatContestFormatType(props.format)}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ color: 'text.secondary' }}>Количество побед</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{props.settings.winsCount}</Typography>
                </Grid>

            </Grid>
        </Box>
    );
}