import Resizer from "react-image-file-resizer";
import {Avatar} from "@mui/material";
import Uploady, {UPLOADER_EVENTS, UploadyContext, useRequestPreSend} from "@rpldy/uploady";
import {TokenService} from "../common/TokenService";
import UploadButton from "@rpldy/upload-button";
import React, {useContext, useEffect} from "react";

const AcceptFiles = '.png,.jpg,.jpeg';
const ResizeSize = 100;
const ResizeFormat = 'png';
const ResizeQuality = 100;

export function AvatarUploader(props: {
    avatar: string,
    url: string,
    onFinish: () => void,
    onError: (message: string) => void
}) {
    return (
        <div>
            <Avatar sx={{width: 56, height: 56, mt: 2, mb: 1}} src={props.avatar}/>

            <Uploady
                destination={{
                    url: props.url,
                    headers: {'Authorization': 'Bearer ' + new TokenService().getToken()},
                }}
                accept={AcceptFiles}
                multiple={false}
            >
                <UploadButton text={'Загрузить'}/>
                <ResizeComponent/>
                <UploaderEventListener onFinish={props.onFinish} onError={props.onError}/>
            </Uploady>
        </div>
    );
}

function UploaderEventListener(props: { onFinish: () => void, onError: (error: string) => void }) {

    const uploadyContext = useContext(UploadyContext);

    useEffect(() => {

        uploadyContext.on(UPLOADER_EVENTS.ITEM_FINISH, props.onFinish);

        const itemError = (e: any) => {
            const message = e?.uploadResponse?.message ?? 'Что то пошло не так, попробуйте позже';
            props.onError(message);
        };
        uploadyContext.on(UPLOADER_EVENTS.ITEM_ERROR, itemError);

        return () => {
            uploadyContext.off(UPLOADER_EVENTS.ITEM_FINISH, props.onFinish);
            uploadyContext.off(UPLOADER_EVENTS.ITEM_ERROR, itemError);
        };
    }, [uploadyContext]);

    return <></>
}

function ResizeComponent() {
    useRequestPreSend(async ({items, options}) => {
        items[0].file = await resize(items[0].file);
        return {items: items};
    });

    return <></>;
}

function resize(file: any): Promise<File> {
    return new Promise(resolve => {
        Resizer.imageFileResizer(file, ResizeSize, ResizeSize, ResizeFormat, ResizeQuality, 0, uri => resolve(uri as any), 'file');
    });
}