import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import {accountSlice} from "../features/account/accountSlice";
import menu from "./menu";
import {coreSlice} from "../features/core/coreSlice";

export const store = configureStore({
    reducer: {
        account: accountSlice.reducer,
        core: coreSlice.reducer,
        menu: menu
    }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;