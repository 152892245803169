import {
    Alert,
    Avatar,
    Box,
    Container,
    CssBaseline, Link,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {AccountService} from "./AccountService";
import {ErrorFormatter} from "../common/ErrorFormatter";

let activationStarted = false;

export function AccountActivationPage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [activated, setActivated] = useState(false);

    useEffect(() => {
        if (!navigate || !searchParams || activationStarted) {
            return;
        }

        const email = searchParams.get('email');
        if (!email) {
            navigate('/error');
            return;
        }

        const token = searchParams.get('token');
        if (!token) {
            navigate('/error');
            return;
        }

        activationStarted = true;

        const accountService = new AccountService();
        accountService.activateAccount(email, token)
            .then(() => {
                setLoading(false);
                setActivated(true);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    }, [navigate, searchParams]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Активация аккаунта
                </Typography>

                {
                    error &&
                    <Alert sx={{mt: 2}} severity='error'>
                        {ErrorFormatter.format(error)}
                    </Alert>
                }

                {
                    activated &&
                    <>
                        <>
                            <Alert sx={{mt: 2}} severity='success'>
                                {`Аккаунт активирован, войдите в систему`}
                            </Alert>

                            <Link sx={{mt: 2}} component={RouterLink} to='/account/signin'>Вход / регистрация</Link>
                        </>
                    </>
                }

                {
                    loading &&
                    <Box component="form" sx={{mt: 2}}>
                        <span>Пожалуйста подождите...</span>
                    </Box>
                }

            </Box>
        </Container>
    );
}