import {
    Alert,
    Avatar,
    Box,
    Container,
    CssBaseline, Link,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useFormik} from "formik";
import {EmailValidator} from "./EmailValidator";
import {AccountService} from "./AccountService";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {Link as RouterLink} from "react-router-dom";

export function PasswordRecoveryPage() {

    const [error, setError] = useState<any>();
    const [submitted, setSubmitted] = useState(false);

    const formik = useFormik<ForgetForm>({
        initialValues: {
            email: '',
        },
        validate: values => {
            const errors = {};
            if (values.email.trim().length === 0) {
                Object.assign(errors, {email: 'Введите почтовый адрес'});
            }
            if (!EmailValidator.validate(values.email)) {
                Object.assign(errors, {email: 'Введите корректный email адрес'});
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(undefined);

                const accountService = new AccountService();
                await accountService.sendPasswordRecoveryEmail(values.email.trim());

                setSubmitted(true);
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Восстановление пароля
                </Typography>

                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>

                    {
                        error &&
                        <Alert sx={{mb: 2}} severity='error'>
                            {ErrorFormatter.format(error)}
                        </Alert>
                    }

                    {
                        submitted &&
                        <>
                            <Alert sx={{mb: 2}} severity='success'>
                                {`На почтовый адрес ${formik.values.email} отправлено письмо с инструкцией по восстановлению пароля`}
                            </Alert>

                            <Link component={RouterLink} to='/account/signin'>Вход / регистрация</Link>
                        </>
                    }

                    {
                        !submitted &&
                        <>


                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Почтовый адрес"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />

                            <LoadingButton
                                loading={formik.isSubmitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Восстановить
                            </LoadingButton>

                        </>
                    }

                </Box>
            </Box>
        </Container>
    );
}

interface ForgetForm {
    email: string;
}