import {Alert, Autocomplete, Box, TextField, Typography} from "@mui/material";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {LoadingButton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useAppDispatch} from "../../app/hooks";
import {useSnackbar} from "notistack";
import {login} from "../account/accountSlice";
import {AvatarUploader} from "./AvatarUploader";
import {PinspinClient} from "../../api/PinspinClient";
import {CityClientDto, CoachProfileClientDto, UpdateCoachProfileClientDto} from "../../api/NswagClient";

export function CoachProfileEdit(props: { profile: CoachProfileClientDto }) {

    const dispatcher = useAppDispatch();
    const profile = props.profile;
    const { enqueueSnackbar } = useSnackbar();
    const [cities, setCities] = useState<CityClientDto[]>();
    const [error, setError] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                const citiesResult = await new PinspinClient().getCities(0, 1000);
                setCities(citiesResult.items!);
            } catch (e) {
                setError(e);
            }
        })()
    }, []);

    const formik = useFormik<ProfileEditForm>({
        initialValues: {
            firstName: profile.firstName!,
            lastName: profile.lastName!,
            cityId: profile.cityId!
        },
        validate: values => {
            const errors = {};
            if (values.firstName.trim().length === 0) {
                Object.assign(errors, { firstName: 'Не должно быть пустым' });
            }
            if (values.lastName.trim().length === 0) {
                Object.assign(errors, { lastName: 'Не должно быть пустым' });
            }
            if (!values.cityId || values.cityId <= 0) {
                Object.assign(errors, { cityId: 'Необходимо выбрать' });
            }
            return errors;
        },
        onSubmit: async (values, fmk) => {
            try {
                setError(null);
                await new PinspinClient().updateCoachProfile(profile.coachId!, new UpdateCoachProfileClientDto({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    cityId: values.cityId
                }));
                fmk.resetForm({ values: values });
                dispatcher(login());
                enqueueSnackbar('Профиль обновлен', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    const handleUploadFinished = () => {
        dispatcher(login());
        enqueueSnackbar('Аватар обновлен', { variant: "success" });
    };

    const handleUploadError = (error: string) => {
        enqueueSnackbar(error, { variant: "error" });
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));
    const cityIds = (cities ?? []).sort((a: CityClientDto, b: CityClientDto) => a.name! < b.name! ? -1 : 1).map(x => x.cityId);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }}>

            <Typography component="h1" variant="h5">
                Редактирование профиля
            </Typography>

            <AvatarUploader
                avatar={profile.avatar!}
                url={`/api/coach/${profile.coachId}/avatar`}
                onFinish={handleUploadFinished}
                onError={handleUploadError}
            />

            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>

                {
                    error &&
                    <Alert sx={{ mb: 2 }} severity='error'>
                        {ErrorFormatter.format(error)}
                    </Alert>
                }

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Имя"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Фамилия"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />

                <Autocomplete
                    sx={{ mt: 2 }}
                    fullWidth
                    id='cityId'
                    options={cityIds}
                    getOptionLabel={x => cityNames.get(x) ?? '-'}
                    noOptionsText='Ничего не найдено'
                    value={formik.values.cityId || null}
                    onChange={(e, v) => formik.setFieldValue('cityId', v)}
                    renderInput={(params) => <TextField {...params} name="cityId" label='Город'
                                                        error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                                                        helperText={formik.touched.cityId && formik.errors.cityId}/>}
                />

                <LoadingButton
                    sx={{ mt: 3, mb: 2 }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={formik.isSubmitting}
                    disabled={!formik.dirty}
                >
                    Сохранить
                </LoadingButton>
            </Box>

        </Box>
    );
}

interface ProfileEditForm {
    firstName: string;
    lastName: string;
    cityId: number;
}