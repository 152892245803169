import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AccountService} from "./AccountService";
import {AppThunk, RootState} from "../../app/store";
import {
    AccountRoleClientDto, IAccountClientDto,
    ICoachProfileClientDto, IGymProfileClientDto,
    IPlayerProfileClientDto, IStoreProfileClientDto
} from "../../api/NswagClient";
import {PinspinClient} from "../../api/PinspinClient";

export type Profile = IPlayerProfileClientDto | ICoachProfileClientDto | IGymProfileClientDto | IStoreProfileClientDto;

export interface AccountState {
    account?: IAccountClientDto;
    profile?: Profile;
}

const initialState: AccountState = {};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount: (state, action: PayloadAction<IAccountClientDto | undefined>) => {
            state.account = action.payload;
        },
        setProfile: (state, action: PayloadAction<Profile | undefined>) => {
            state.profile = action.payload;
        }
    }
});


export const login = (): AppThunk => async (dispatch, getState) => {
    try {
        const account = await new AccountService().getAccount();
        let profile: Profile | undefined;

        if (account.role === AccountRoleClientDto.Player) {
            profile = await new PinspinClient().getPlayerProfile(account.userId!);
        } else if (account.role === AccountRoleClientDto.Coach) {
            profile = await new PinspinClient().getCoachProfile(account.userId!);
        } else if (account.role === AccountRoleClientDto.Gym) {
            profile = await new PinspinClient().getGymProfile(account.userId!);
        } else if (account.role === AccountRoleClientDto.Store) {
            profile = await new PinspinClient().getStoreProfile(account.userId!);
        }

        dispatch(setAccount({ ...account }));
        dispatch(setProfile({ ...profile }));
    } catch (e) {

    }
};

export const logout = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(setAccount(undefined));
        dispatch(setProfile(undefined));
        await new AccountService().signOut();
    } catch (e) {

    }
};

export const { setAccount, setProfile } = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account.account;
export const selectProfile = (state: RootState) => state.account.profile;