import React from 'react';
import ReactDOM from 'react-dom/client';

import {RouterProvider} from "react-router-dom";
import {router} from "./routes/router";
import {Provider} from "react-redux";
import {store} from "./app/store";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            light: '#14b0ee',
            main: '#008fcc',
            dark: '#005e97',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fd9700',
            main: '#fc8600',
            dark: '#fc6500',
            contrastText: '#000',
        },
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <RouterProvider router={router}/>
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
