import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {login, selectAccount} from "../account/accountSlice";
import {Alert, Box, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {AccountService} from "../account/AccountService";
import {UsernameValidator} from "../account/UsernameValidator";
import {PasswordValidator} from "../account/PasswordValidator";
import {IAccountClientDto} from "../../api/NswagClient";

export function ProfileSettingsPage() {

    const account = useAppSelector(selectAccount);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>

            <Typography component="h1" variant="h5">
                Настройки
            </Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}>

                <Typography variant='subtitle1' sx={{ mt: 2 }}>
                    Логин
                </Typography>

                {account && <ChangeUsernameForm account={account}/>}

            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}>

                <Typography variant='subtitle1' sx={{ mt: 2 }}>
                    Пароль
                </Typography>

                {account && <ChangePasswordForm account={account}/>}

            </Box>

        </Box>
    );
}

interface ChangeUsernameFormValues {
    username: string;
}

function ChangeUsernameForm(props: { account: IAccountClientDto }) {

    const dispatcher = useAppDispatch();
    const account = props.account;
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<any>();

    const formik = useFormik<ChangeUsernameFormValues>({
        initialValues: {
            username: account?.username ?? ''
        },
        validate: values => {
            const errors = {};

            const validationResult = UsernameValidator.validate(values.username);
            if (!validationResult.isValid) {
                Object.assign(errors, { username: validationResult.error });
            }

            return errors;
        },
        onSubmit: async (values, fmk) => {
            try {
                setError(null);
                await new AccountService().changeUsername(values.username.trim());
                fmk.resetForm({ values: values });

                dispatcher(login());
                enqueueSnackbar('Логин изменен', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>

            {
                error &&
                <Alert sx={{ mb: 2 }} severity='error'>
                    {ErrorFormatter.format(error)}
                </Alert>
            }

            <TextField
                margin="normal"
                required
                fullWidth
                label="Логин"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
            />

            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={formik.isSubmitting}
                sx={{ mt: 3, mb: 2 }}
                disabled={!formik.dirty}
            >
                Сохранить
            </LoadingButton>

        </Box>
    );
}

interface ChangePasswordFormValues {
    password: string;
    newPassword: string;
    repeatNewPassword: string;
}

function ChangePasswordForm(props: { account: IAccountClientDto }) {

    const dispatcher = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<any>();

    const formik = useFormik<ChangePasswordFormValues>({
        initialValues: {
            password: '',
            newPassword: '',
            repeatNewPassword: ''
        },
        validate: values => {
            const errors = {};

            const validationResult = PasswordValidator.validate(values.newPassword);
            if (!validationResult.isValid) {
                Object.assign(errors, { newPassword: validationResult.error });
            }

            if (values.newPassword !== values.repeatNewPassword) {
                Object.assign(errors, { repeatNewPassword: 'Пароли должны совпадать' });
            }

            return errors;
        },
        onSubmit: async (values, fmk) => {
            try {
                setError(null);
                await new AccountService().changePassword(values.password, values.newPassword);
                fmk.resetForm();

                dispatcher(login());
                enqueueSnackbar('Пароль изменен', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>

            {
                error &&
                <Alert sx={{ mb: 2 }} severity='error'>
                    {ErrorFormatter.format(error)}
                </Alert>
            }

            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Текущий пароль"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="Новый пароль"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                name="repeatNewPassword"
                label="Повторите новый пароль"
                type="password"
                value={formik.values.repeatNewPassword}
                onChange={formik.handleChange}
                error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
                helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
            />

            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={formik.isSubmitting}
                sx={{ mt: 3, mb: 2 }}
                disabled={!formik.dirty}
            >
                Сохранить
            </LoadingButton>

        </Box>
    );
}