import React, {useRef, useState} from "react";
import {Badge, Box, Card, CardContent, ClickAwayListener, IconButton, Paper, Popper, Typography} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export function Notification() {

    const anchor = useRef(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <IconButton color="inherit" ref={anchor} onClick={handleToggle}>
                <Badge badgeContent={null} color="secondary">
                    <NotificationsIcon/>
                </Badge>
            </IconButton>

            <Popper open={open} anchorEl={anchor.current} placement={'bottom-end'} disablePortal>
                <Paper sx={{
                    width: '100%',
                    minWidth: 285,
                    maxWidth: 285,
                }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Card>
                            <CardContent>
                                <Typography>Пока нет уведомлений</Typography>
                            </CardContent>
                        </Card>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </Box>
    );
}