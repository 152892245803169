import {Box, Typography} from "@mui/material";

export function FaqPage() {

    let id = 1;

    const faq = [
        {
            id: id++,
            question: 'Что такой рейтинг и уровень игрока?',
            answer: 'Рейтинг и уровень игрока - это оценка навыков игрока принятая в сервисе и никак не связана с официальными рейтингами и званиями федераций. Цель рейтинга и уровня - помощь в поиске партнера/соперника, оценка своего прогресса и мотивация на развитие'
        },
        {
            id: id++,
            question: 'Как рассчитывается рейтинг и уровень игрока?',
            answer: 'Рейтинг рассчитвается по формуле Эло после официального подтверждения результата игры между игроками'
        },
        {
            id: id++,
            question: 'Что делать если соперник не подтверждает результат внесенной игры?',
            answer: 'Сервис работает на предположении честности и добросовестности соперников и не предоставляет средств арбитража.' +
                'Наша рекомендация - при рейтинговой игре, заранее обговаривать правила занесения результатов в систему, поскольку вы встречаетесь лицом к лицу.'
        }
    ];


    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper', p: 2}}>

            <Typography component="h1" variant="h5">Часто задаваемые вопросы</Typography>

            {
                faq.map(x =>
                    <div key={x.id} className="mb-5">

                        <Typography variant='subtitle1' sx={{mt: 2}}>
                            {x.question}
                        </Typography>

                        <Typography sx={{mt: 1}}>
                            {x.answer}
                        </Typography>
                    </div>
                )
            }

        </Box>
    );
}