import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {PlayerFormatter} from "./PlayerFormatter";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {PinspinClient} from "../../api/PinspinClient";
import {PlayerInfoClientDto} from "../../api/NswagClient";

export function PlayerListPage() {

    const [players, setPlayers] = useState<PlayerInfoClientDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getPlayers(0, 50)
            .then(x => setPlayers(x.items!))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

            {
                !loading && !error &&
                <List>
                    {
                        players.map(x =>
                            <ListItem key={x.username} disableGutters disablePadding>
                                <ListItemButton component={Link} to={`/players/${x.username}`}>
                                    <ListItemAvatar>
                                        <Avatar alt={x.username} src={x.avatar}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={PlayerFormatter.formatName(x)}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </List>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </Box>
    );
}