export class TokenService {

    private access_token = 'access_token';

    saveToken(token: string, remember: boolean = true) {
        if (remember) {
            localStorage.setItem(this.access_token, token);
        } else {
            sessionStorage.setItem(this.access_token, token);
        }
    }

    getToken() {
        return sessionStorage.getItem(this.access_token) ?? localStorage.getItem(this.access_token);
    }

    clearToken() {
        sessionStorage.removeItem(this.access_token);
        localStorage.removeItem(this.access_token);
    }
}