import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Avatar, Box, Paper, Typography} from "@mui/material";
import {PlayerFormatter} from "./PlayerFormatter";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {useSelector} from "react-redux";
import {selectCities} from "../core/coreSlice";
import {PinspinClient} from "../../api/PinspinClient";
import {PlayerClientDto} from "../../api/NswagClient";
import {TitleComponent} from "../common/TitleComponent";

export function PlayerPage() {

    const params = useParams<{ name: string }>();
    const cities = useSelector(selectCities);
    const [player, setPlayer] = useState<PlayerClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getPlayer(params.name ?? '')
            .then(x => setPlayer(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.name]);

    const locationsMap = new Map(cities.map(x => [x.cityId, x.name]));

    return (
        <>
            {
                !loading && !error && player &&
                <>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Avatar src={player.avatar} sx={{ width: 56, height: 56 }} variant='circular'/>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TitleComponent>
                                    {PlayerFormatter.formatName(player)}
                                </TitleComponent>
                                <Typography>
                                    {locationsMap.get(player.locationId) ?? ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>

                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Игровая рука
                            </Typography>
                            <Typography noWrap>
                                {
                                    PlayerFormatter.formatDominantHand(player.dominantHand!)
                                }
                            </Typography>
                        </Paper>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Стиль игры
                            </Typography>
                            <Typography noWrap>
                                {
                                    PlayerFormatter.formatPlayingStyle(player.playingStyle!)
                                }
                            </Typography>
                        </Paper>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Хват ракетки
                            </Typography>
                            <Typography noWrap>
                                {
                                    PlayerFormatter.formatGrip(player.grip!)
                                }
                            </Typography>
                        </Paper>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Рейтинг
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography noWrap>
                                    300
                                </Typography>
                                <Typography sx={{ fontSize: 12, ml: 1, color: 'success.main' }} noWrap>
                                    +10
                                </Typography>
                            </Box>
                        </Paper>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Место
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography noWrap>
                                    123
                                </Typography>
                                <Typography sx={{ fontSize: 12, ml: 1, color: 'success.main' }} noWrap>
                                    +7
                                </Typography>
                            </Box>
                        </Paper>
                        <Paper elevation={0} sx={{ p: 2, flexGrow: 1, flexBasis: 0 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
                                Игры
                            </Typography>
                            <Typography noWrap>
                                55
                            </Typography>
                        </Paper>
                    </Box>
                </>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </>

    );
}
