import {NswagClient} from "./NswagClient";

import axios from "axios";
import {TokenService} from "../features/common/TokenService";

export class PinspinClient extends NswagClient {

    protected tokenService: TokenService;

    constructor() {
        super();
        this.tokenService = new TokenService();
        this.instance = axios.create({});
        this.instance.interceptors.request.use(config => {
            const token = this.tokenService.getToken();
            if (token && config.headers) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
    }
}