import {useSnackbar} from "notistack";
import {useState} from "react";
import {ErrorFormatter} from "../../common/ErrorFormatter";
import {
    Avatar, Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem, Paper,
    Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ContestFormatter} from "../ContestFormatter";
import {PinspinClient} from "../../../api/PinspinClient";
import {
    ContestClientDto,
    ContestParticipantClientDto,
    ContestParticipantStatusClientDto, IAccountClientDto
} from "../../../api/NswagClient";

export function ContestParticipants(props: {
    account?: IAccountClientDto,
    contest: ContestClientDto,
    cities: Map<number, string>,
    onAccept: () => Promise<void>
}) {

    const contest = props.contest;
    const { enqueueSnackbar } = useSnackbar();
    const [submitting, setSubmitting] = useState(false);
    const [participantPopupMenuAnchorEl, setParticipantPopupMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [participantPopupMenuItem, setParticipantPopupMenuItem] = useState<ContestParticipantClientDto>();

    const handleParticipantPopupMenuOpen = (event: any, participant: ContestParticipantClientDto) => {
        setParticipantPopupMenuAnchorEl(event.currentTarget);
        setParticipantPopupMenuItem(participant);
    };

    const handleParticipantPopupMenuClose = () => {
        setParticipantPopupMenuAnchorEl(null);
        setParticipantPopupMenuItem(undefined);
    };

    const handleAccept = async () => {
        handleParticipantPopupMenuClose();
        try {
            if (!participantPopupMenuItem) {
                return;
            }
            setSubmitting(true);

            await new PinspinClient().acceptParticipant(contest.contestId!, participantPopupMenuItem.participantId!);
            enqueueSnackbar(' Заявка одобрена', { variant: "success" });

            await props.onAccept();
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setSubmitting(false);
        }
    };

    const handleReject = async () => {
        handleParticipantPopupMenuClose();
        try {
            if (!participantPopupMenuItem) {
                return;
            }
            setSubmitting(true);

            await new PinspinClient().rejectParticipant(contest.contestId!, participantPopupMenuItem.participantId!);
            enqueueSnackbar(' Заявка отклонена', { variant: "success" });

            await props.onAccept();
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setSubmitting(false);
        }
    };

    const newParticipants = contest.participants!.filter(x => x.status === ContestParticipantStatusClientDto.New);
    const acceptedParticipants = contest.participants!.filter(x => x.status === ContestParticipantStatusClientDto.Accepted);

    const isOrganizer = props.account && props.account.userId === contest.organizer!.userId;

    return (
        <>
            <Paper sx={{ mt: 1 }} elevation={0}>

                {
                    isOrganizer && newParticipants.length > 0 &&
                    <>
                        <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                            <Typography sx={{ fontSize: 12 }}
                                        color="text.secondary"
                                        noWrap>
                                Заявки
                            </Typography>
                        </Box>

                        <List>
                            {
                                newParticipants.map(x =>
                                    <ListItem
                                        key={x.participantId}
                                        disableGutters
                                        disablePadding
                                        secondaryAction={
                                            <IconButton edge="end" sx={{ mr: 1 }}
                                                        onClick={e => handleParticipantPopupMenuOpen(e, x)}>
                                                <MoreVertIcon/>
                                            </IconButton>
                                        }
                                    >
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar src={x.avatar}/>
                                            </ListItemAvatar>
                                            <ListItemText primary={ContestFormatter.formatParticipantName(x)}
                                                          secondary={`${props.cities.get(x.locationId!) ?? ''}, ${ContestFormatter.formatParticipantRating(x)}`}/>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                    </>
                }

                <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary"
                                noWrap>Участники</Typography>
                </Box>

                {
                    acceptedParticipants.length > 0 &&
                    <List>
                        {
                            acceptedParticipants.map(x =>
                                <ListItem
                                    key={x.participantId}
                                    disableGutters
                                    disablePadding
                                    secondaryAction={
                                        isOrganizer &&
                                        <IconButton edge="end" sx={{ mr: 1 }}
                                                    onClick={e => handleParticipantPopupMenuOpen(e, x)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar src={x.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={ContestFormatter.formatParticipantName(x)}
                                                      secondary={`${props.cities.get(x.locationId!) ?? ''}, ${ContestFormatter.formatParticipantRating(x)}`}/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    </List>
                }

                {
                    acceptedParticipants.length === 0 &&
                    <Box sx={{ pl: 2, pr: 2, pb: 2, pt: 1 }}>
                        <Typography>Пока ни одного участника</Typography>
                    </Box>
                }

            </Paper>

            <Menu
                id="basic-menu"
                anchorEl={participantPopupMenuAnchorEl}
                open={Boolean(participantPopupMenuAnchorEl)}
                onClose={handleParticipantPopupMenuClose}
                MenuListProps={{
                    'aria-labelledby': ' basic-button',
                }}
            >
                <MenuItem onClick={handleAccept} disabled={submitting}>Принять</MenuItem>
                <MenuItem onClick={handleReject} disabled={submitting}>Отклонить</MenuItem>
            </Menu>
        </>
    );
}