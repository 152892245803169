import {Box, Typography} from "@mui/material";

export function RulesPage() {
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper', p: 2}}>

            <Typography component="h1" variant="h5">Правила</Typography>

            <Typography variant='subtitle1' sx={{mt: 2}}>Разрешено</Typography>
            <ul>
                <li>
                    Вносить результаты матчей.
                </li>
                <li>
                    Создавать и вступать в клубы.
                </li>
                <li>
                    Создавать и проводить труниры.
                </li>
            </ul>

            <Typography variant='subtitle1' sx={{mt: 2}}>Запрещено</Typography>
            <ul>
                <li>
                    Использовать в качестве никнейма или изображения профиля название и изображение нарушающие права
                    третьих лиц.
                </li>
                <li>
                    Использовать в качестве никнейма или изображения профиля слова и изображение
                    порнографического/экстремисткого/расисткого характера.
                </li>
                <li>
                    Вносить заведемо ложную и противоречивую информацию в систему.
                </li>
            </ul>

            <Typography sx={{mt: 2}}>
                За нарушение правил сервиса пользователь блокируется на месяц.
                При повторном нарушении правил блокируется навсегда.
            </Typography>

        </Box>
    );
}