import React, {useEffect} from 'react';
import {MainLayout} from "./layout/MainLayout";
import {useAppDispatch} from "./app/hooks";
import {login} from "./features/account/accountSlice";
import {SnackbarProvider} from "notistack";
import {getCities} from "./features/core/coreSlice";

let initialized = false;

export default function AppComponent() {

    const dispatcher = useAppDispatch();

    useEffect(() => {
        if (!dispatcher || initialized) {
            return;
        }
        dispatcher(login());
        dispatcher(getCities());
        initialized = true;
    }, [dispatcher]);

    return (
        <SnackbarProvider maxSnack={3}>
            <MainLayout/>
        </SnackbarProvider>
    );
}

