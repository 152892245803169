import React from "react";
import {Typography} from "@mui/material";
import {Property} from "csstype";

export function TitleComponent(props: { children: React.ReactNode }) {
    return (
        <Typography component="h1" variant="h5">
            {props.children}
        </Typography>
    );
}

export function TagComponent(props: {
    color: Property.Color,
    backgroundColor: Property.Color,
    children: React.ReactNode
}) {
    return (
        <Typography sx={{
            color: props.color,
            borderRadius: 0.5,
            backgroundColor: props.backgroundColor,
            pl: 1,
            pr: 1,
            fontSize: 12,
        }}>
            {props.children}
        </Typography>
    );
}

export function SubHeader(props: { children: React.ReactNode }) {
    return (
        <Typography sx={{ fontSize: 12 }} color="text.secondary" noWrap>
            {props.children}
        </Typography>
    );
}