import {Box, Typography} from "@mui/material";

export function EulaPage() {

    const rules = [
        "Сервис и администрация не несут никакой, прямой или косвенной, ответственности перед пользователем или другими лицами и предоставляется как есть.",
        "Администрация сервиса праве, без объяснения причин, блокировать пользователя в целях безопасности системы.",
        "Запрещается использование названий, знаков или любой другой информации, которые нарушают права третьих лиц.",
        "Пользователь лично несет ответственность за свои действия и предоставленную информацию в системе."
    ];

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper', p: 2}}>

            <Typography component="h1" variant="h5">Пользовательское соглашение</Typography>

            {
                rules.map((x, i) => <Typography variant='body1' sx={{mt: 2}} key={i}>{x}</Typography>)
            }
        </Box>
    );
}