import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import React from "react";
import {ContestClientDto} from "../../../api/NswagClient";

export function TablesSelectStep(props: {
    contest: ContestClientDto,
    tablesCount: number,
    onChange: (tablesCount: number) => void
}) {

    const maxTablesCount = 20;
    const tablesCountOptions = Array.from(Array(maxTablesCount).keys()).map(x => x + 1);

    return (
        <Box sx={{ p: 2 }}>

            <Box>
                <Typography variant='h5'>Столы</Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Количество столов</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.tablesCount}
                        label="Количество столов"
                        onChange={x => props.onChange(Number(x.target.value))}
                    >
                        {
                            tablesCountOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText>
                        Количество столов, на которых одновременно будет проводиться турнир
                    </FormHelperText>
                </FormControl>
            </Box>
        </Box>
    );
}