import {
    Avatar, Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {ContestFormatter} from "../ContestFormatter";
import {ContestClientDto} from "../../../api/NswagClient";

export function ContestOrganizers(props: { contest: ContestClientDto }) {

    const contest = props.contest;

    return (
        <Paper sx={{ mt: 1 }} elevation={0}>

            <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                <Typography sx={{ fontSize: 12 }}
                            color="text.secondary"
                            noWrap>
                    Организаторы
                </Typography>
            </Box>

            <List>
                <ListItem key={1} disableGutters disablePadding>
                    <ListItemButton component={Link}
                                    to={ContestFormatter.formatOrganizerLink(contest.organizer!)}>
                        <ListItemAvatar>
                            <Avatar src={contest.organizer!.avatar}/>
                        </ListItemAvatar>
                        <ListItemText primary={ContestFormatter.formatOrganizerName(contest.organizer!)}/>
                    </ListItemButton>
                </ListItem>
            </List>

        </Paper>
    );
}