import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export function ConfirmDialog(props: {
    open: boolean,
    title: string,
    body?: string,
    onOk: (() => Promise<void>) | (() => void),
    onClose: (() => Promise<void>) | (() => void)
}) {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>

            {
                props.body &&
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.body}
                    </DialogContentText>
                </DialogContent>
            }

            <DialogActions>
                <Button onClick={props.onClose}>Отмена</Button>
                <Button onClick={props.onOk} autoFocus>ОК</Button>
            </DialogActions>
        </Dialog>
    );
}