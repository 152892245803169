import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Avatar, Box, Typography} from "@mui/material";
import {LoadingComponent} from "../common/LoadingComponent";
import {ErrorComponent} from "../common/ErrorComponent";
import {GymFormatter} from "./GymFormatter";
import {PinspinClient} from "../../api/PinspinClient";
import {GymClientDto} from "../../api/NswagClient";

export function GymPage() {

    const params = useParams<{ username: string }>();
    const [gym, setGym] = useState<GymClientDto>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        new PinspinClient().getGym(params.username ?? '')
            .then(x => setGym(x))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [params.username]);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {
                !loading && !error && gym &&
                <>
                    <Box sx={{ display: 'flex', gap: 2, p: 2 }}>
                        <Avatar src={gym.avatar} sx={{ width: 56, height: 56 }} variant='circular'/>
                        <Typography component="h1" variant="h5">{GymFormatter.formatName(gym)}</Typography>
                    </Box>
                </>
            }

            {
                loading && <LoadingComponent/>
            }

            {
                error && <ErrorComponent error={error}/>
            }
        </Box>
    );
}