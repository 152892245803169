import {
    AccountRoleClientDto,
    ActivateAccountCommand, ChangePasswordClientDto,
    ChangeUsernameClientDto,
    ResetPasswordCommand,
    SendAccountActivationEmailCommand,
    SendPasswordRecoveryEmailCommand,
    SignInCommand,
    SignUpCommand,
    SignUpSpecificationClientDto
} from "../../api/NswagClient";
import {PinspinClient} from "../../api/PinspinClient";
import {TokenService} from "../common/TokenService";

export class AccountService {

    private tokenService: TokenService;

    constructor() {
        this.tokenService = new TokenService();
    }

    async getAccount() {
        return await new PinspinClient().current();
    }

    signIn(email: string, password: string, remember: boolean): Promise<string> {
        return new PinspinClient()
            .login(new SignInCommand({ email: email, password: password }))
            .then(accessToken => {
                this.tokenService.saveToken(accessToken, remember);
                return accessToken;
            });
    }

    signOut(): Promise<void> {
        return new Promise(resolve => {
            this.tokenService.clearToken();
            resolve();
        });
    }

    signUp(specification: {
        username: string,
        role: AccountRoleClientDto,
        email: string,
        password: string
    }): Promise<void> {
        return new PinspinClient().signUp(new SignUpCommand({ specification: new SignUpSpecificationClientDto(specification) }));
    }

    activateAccount(email: string, token: string): Promise<void> {
        return new PinspinClient().activate(new ActivateAccountCommand({ email: email, token: token }));
    }

    sendActivationEmail(email: string): Promise<void> {
        return new PinspinClient().send(new SendAccountActivationEmailCommand({ email: email }));
    }

    sendPasswordRecoveryEmail(email: string): Promise<void> {
        return new PinspinClient().recovery(new SendPasswordRecoveryEmailCommand({ email: email }));
    }

    resetPassword(email: string, token: string, password: string): Promise<void> {
        return new PinspinClient().reset(new ResetPasswordCommand({
            email: email,
            token: token,
            password: password
        }));
    }

    changeUsername(newUsername: string): Promise<void> {
        return new PinspinClient().changeUsername(new ChangeUsernameClientDto({ newUsername: newUsername }));
    }

    changePassword(password: string, newPassword: string): Promise<void> {
        return new PinspinClient().changePassword(new ChangePasswordClientDto({
            password: password,
            newPassword: newPassword
        }));
    }
}