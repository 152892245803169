import React, {useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Container,
    CssBaseline, FormControl, FormHelperText,
    InputLabel, Link,
    MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {AccountService} from "./AccountService";
import {useFormik} from "formik";
import {EmailValidator} from "./EmailValidator";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {LoadingButton} from "@mui/lab";
import {UsernameValidator} from "./UsernameValidator";
import {PasswordValidator} from "./PasswordValidator";
import {AccountRoleClientDto} from "../../api/NswagClient";

export function SignUpPage() {

    const [error, setError] = useState<any>();
    const [submitted, setSubmitted] = useState(false);

    const roles = [
        {
            id: AccountRoleClientDto.Player,
            name: 'Игрок'
        },
        {
            id: AccountRoleClientDto.Coach,
            name: 'Тренер'
        },
        {
            id: AccountRoleClientDto.Gym,
            name: 'Площадка'
        },
        {
            id: AccountRoleClientDto.Store,
            name: 'Магазин'
        }
    ];

    const formik = useFormik<SignUpForm>({
        initialValues: {
            username: '',
            roleId: AccountRoleClientDto.Player,
            email: '',
            password: '',
            repeatPassword: ''
        },
        validate: values => {
            const errors = {};
            const usernameValidationResult = UsernameValidator.validate(values.username);
            if (!usernameValidationResult.isValid) {
                Object.assign(errors, { username: usernameValidationResult.error });
            }
            if (!values.roleId) {
                Object.assign(errors, { roleId: 'Необходимо выбрать' });
            }
            if (values.email.trim().length === 0) {
                Object.assign(errors, { email: 'Не должно быть пустым' });
            }
            if (!EmailValidator.validate(values.email)) {
                Object.assign(errors, { email: 'Введите корректный email адрес' });
            }

            const passwordValidationResult = PasswordValidator.validate(values.password);
            if (!passwordValidationResult.isValid) {
                Object.assign(errors, { password: passwordValidationResult.error });
            }
            if (values.password !== values.repeatPassword) {
                Object.assign(errors, { repeatPassword: 'Пароли должны совпадать' });
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                setError(undefined);

                const accountService = new AccountService();
                await accountService.signUp({
                    username: values.username.trim(),
                    role: values.roleId,
                    email: values.email.trim(),
                    password: values.password.trim()
                });

                setSubmitted(true);
            } catch (e) {
                setError(e);
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>

                <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>

                    {
                        error &&
                        <Alert sx={{ mb: 2 }} severity='error'>
                            {ErrorFormatter.format(error)}
                        </Alert>
                    }

                    {
                        submitted &&
                        <>
                            <Alert sx={{ mb: 2 }} severity='success'>
                                {`На почтовый адрес ${formik.values.email} отправлено письмо с инструкцией по активации аккаунта`}
                            </Alert>

                            <Link component={RouterLink} to='/account/signin'>Вход / регистрация</Link>
                        </>
                    }

                    {
                        !submitted &&
                        <>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Логин"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />

                            <FormControl fullWidth sx={{ mt: 2 }} required>
                                <InputLabel id="roleId">Роль</InputLabel>
                                <Select
                                    labelId="roleId"
                                    label='Роль'
                                    name='roleId'
                                    value={formik.values.roleId}
                                    onChange={formik.handleChange}
                                >
                                    {
                                        roles.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText error={formik.touched.roleId && Boolean(formik.errors.roleId)}>
                                    {formik.touched.roleId && formik.errors.roleId}
                                </FormHelperText>
                            </FormControl>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Почтовый адрес"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repeatPassword"
                                label="Повторите пароль"
                                type="password"
                                value={formik.values.repeatPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                            />

                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={formik.isSubmitting}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Зарегистрироваться
                            </LoadingButton>

                        </>
                    }
                </Box>
            </Box>
        </Container>
    );
}

interface SignUpForm {
    username: string;
    roleId: AccountRoleClientDto;
    email: string;
    password: string;
    repeatPassword: string;
}